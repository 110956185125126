@tailwind base;
@tailwind components;
@tailwind utilities;

/* Integrate custome fonts */
@layer base {
  @font-face {
    font-family: "Bebas";
    src: process.env.REACT_APP_API_URL + "/fonts/bebas.otf";
  }
  @font-face {
    font-family: "Bodony";
    src: process.env.REACT_APP_API_URL + "/fonts/bodony72.ttc";
  }
  @font-face {
    font-family: "Bebas Neue";
    src: "https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap";
  }
}

/*Custom utility classes for the features Tailwind doesn't natively support with react*/

.background-image-1 {
  background-image: url("./assets/img/background-1.jpg");
}

.background-road {
  background-image: url("./assets/icons/wave-road.svg");
  background-repeat: no-repeat;
}
